import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`automation share:
  - alias: 'Share state test'
    trigger:
      - platform: state
        entity_id:
          - switch.my_switch
          - light.my_ligths
    action:
      - service_template: 'homeassistant.turn_{{ trigger.to_state.state | lower}}'
        data_template:
          entity_id: >-
            {% set entities = ["light", "switch"] %}
            {{ entities |reject("equalto", trigger.entity_id)|list }}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      